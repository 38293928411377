@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
}
a {
  color: #34586e;
}
@keyframes scroll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(1rem - 4px));
  }
}

.App {
  min-height: 100vh;
  background: #f6f6f6;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #34586e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #447594;
}
