@keyframes a {
  to {
    transform: scale(0.8);
    background: red;
  }
}
.rr-player {
  position: relative;
  background: white;
  float: left;
}
.rr-player__frame {
  overflow: hidden;
}
.replayer-mouse-tail{
  display: none!important;
}
.rr-player__controls {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9));
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  h4,
  h5,
  span {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  }
  #controls {
    display: flex;
    align-items: center;
    #timer {
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin: 0 10px;
      #timeline {
        flex-grow: 1;
        height: 8px;
        margin: 0 10px;
        background-color: rgba(255, 255, 255, 0.4);
        cursor: pointer;
        &.skipping {
          opacity: 0.4;
          cursor: default;
        }
        &:hover {
          box-shadow: 0 0 3px 3px rgba(255, 255, 255, 0.2);
        }
        #progress {
          height: 100%;
        }
      }
    }
  }
}

.replayer-mouse {
  position: absolute;
  width: 20px;
  height: 20px;
  transition: 0.05s linear;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRhdGEtbmFtZT0iTGF5ZXIgMSIgdmlld0JveD0iMCAwIDUwIDUwIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPkRlc2lnbl90bnA8L3RpdGxlPjxwYXRoIGQ9Ik00OC43MSw0Mi45MUwzNC4wOCwyOC4yOSw0NC4zMywxOEExLDEsMCwwLDAsNDQsMTYuMzlMMi4zNSwxLjA2QTEsMSwwLDAsMCwxLjA2LDIuMzVMMTYuMzksNDRhMSwxLDAsMCwwLDEuNjUuMzZMMjguMjksMzQuMDgsNDIuOTEsNDguNzFhMSwxLDAsMCwwLDEuNDEsMGw0LjM4LTQuMzhBMSwxLDAsMCwwLDQ4LjcxLDQyLjkxWm0tNS4wOSwzLjY3TDI5LDMyYTEsMSwwLDAsMC0xLjQxLDBsLTkuODUsOS44NUwzLjY5LDMuNjlsMzguMTIsMTRMMzIsMjcuNThBMSwxLDAsMCwwLDMyLDI5TDQ2LjU5LDQzLjYyWiI+PC9wYXRoPjwvc3ZnPg==);
  &:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #4950f6;
    transform: translate(-10px, -10px);
    opacity: 0.3;
  }
  &.active:after {
    animation: a 0.4s ease-in-out 1;
  }
}

.replayer-wrapper {
  float: left;
  clear: both;
  position: relative;
  transform-origin: top left;
  left: 50%;
  top: 50%;
  pointer-events: none;
  user-select: none;
}

.replayer-wrapper > iframe {
  border: none;
}
